<template>
  <div>
    <v-container>
      <div class="pa-5 contentBox">
        <v-card-title class="justify-center">
          <h2>
            ریشه در خاک
          </h2>
        </v-card-title>
        <v-card-text class="text-center">
          <div class="mt-7">
            <img src="../../assets/tree/web.jpg" width="100%" style="border-radius: 10px"
                 v-if="$vuetify.breakpoint.mdAndUp" alt="">
            <img src="../../assets/tree/mob.jpg" width="100%" style="border-radius: 10px" v-else alt="">
          </div>
          <p class="mt-5">
            ما در سیمیاروم از امروز برای قدردانی از همراهانی که در مسیر بهبود کیفیت زندگی و رسیدن به سلامت روان، از
            دوستان خود دعوت کرده‌اند تا تراپی را با سیمیاروم ادامه دهند،
            <b style="color: black">
              تصمیم گرفتیم یک نهال در خاک ایران با نام آن‌ها
              بکاریم
            </b>
            تا نشانه‌ای باشد از مراقبت و توجهی که به دوستان خود دارند و این که بدانند جایشان در ایران همیشه سبز
            است.
            <br>
            <br>
            لطفا هر زمان که سیمیاروم را به دوستی معرفی کردید، به تیم پشتیبانی اطلاع دهید تا هدیه سبزتان را ارسال کنیم.
          </p>
          <a href="https://wa.me/989302672054" target="_blank">
            <v-btn color="primary" elevation="0">
              تماس با پشتیبانی
              <v-icon class="mr-1">mdi-whatsapp</v-icon>
            </v-btn>
          </a>
        </v-card-text>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentBox{
    width: 90% !important;
  }
}
.contentBox{
  width: 80%;
  margin: 0px auto;
  background: white;
  padding: 20px;
  border-radius: 18px;
  border: solid 1px #d1dcff;
}
</style>